





































import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateAvvioAttivitaModule } from "../store";
import { OfferServiceModule } from "../../store";
import { PgaAvvioAttivitaSezione5CreateModel } from "../store";

@Component({
  data: () => ({
  }),

  computed: {
    nominativo: {
      get() {
        return this.$props.Model.Nominativo;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_NOMINATIVO5({
          index: this.$props.index,
          value: value
        });
      }
    },
    ruolo: {
      get() {
        return this.$props.Model.Ruolo;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_RUOLO5({
          index: this.$props.index,
          value: value
        });
      }
    },
    giornate: {
      get() {
        return this.$props.Model.Giornate;
      },
      set(value: number) {
        CreateAvvioAttivitaModule.SET_GIORNATE5({
          index: this.$props.index,
          value: value
        });
      }
    }
  }
})
export default class PgaAvvioAttivitaSezione5HeaderCreate extends Vue {
  @Prop(Number) readonly index!: number;
  @Model("", { type: Object }) readonly Model!: PgaAvvioAttivitaSezione5CreateModel;
  @Emit()
  removeTeam(n: number) {
    CreateAvvioAttivitaModule.REMOVE_TEAM5(this.index);
  }
}
