













import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { PgaAvvioAttivitaSezione4HeaderCreateModel } from "../store";

@Component({
  data: () => ({
    enabled: false
  }),
  computed: {
    pm: {
      get() {
        return this.$props.Model.PM;
      }
    },
    mol: {
      get() {
        return this.$props.Model.Mol;
      }
    },
  },
})
export default class PgaAvvioAttivitaSezione4HeaderCreate extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaAvvioAttivitaSezione4HeaderCreateModel;
}
