
































































// @ is an alias to /src
import { Vue, Component } from "vue-property-decorator";
import { CreateAvvioAttivitaModule } from "./store";
import PgaAvvioAttivitaSezione1Create from "./Components/PgaAvvioAttivitaSezione1Create.vue";
import PgaAvvioAttivitaSezione2Create from "./Components/PgaAvvioAttivitaSezione2Create.vue";
import PgaAvvioAttivitaSezione3Create from "./Components/PgaAvvioAttivitaSezione3Create.vue";
import PgaAvvioAttivitaSezione4Create from "./Components/PgaAvvioAttivitaSezione4Create.vue";
import PgaAvvioAttivitaSezione4HeaderCreate from "./Components/PgaAvvioAttivitaSezione4HeaderCreate.vue";
import PgaAvvioAttivitaSezione5Create from "./Components/PgaAvvioAttivitaSezione5Create.vue";
import PgaAvvioAttivitaSezione5HeaderCreate from "./Components/PgaAvvioAttivitaSezione5HeaderCreate.vue";

@Component({
    components: {
        PgaAvvioAttivitaSezione1Create,
        PgaAvvioAttivitaSezione2Create,
        PgaAvvioAttivitaSezione3Create,
        PgaAvvioAttivitaSezione4Create,
        PgaAvvioAttivitaSezione4HeaderCreate,
        PgaAvvioAttivitaSezione5Create,
        PgaAvvioAttivitaSezione5HeaderCreate
    },
    data: () => ({
        e1: 1,
        counter: 0,
        vertical: true,
        editable: true,
    }),
    computed: {
        PgaAvvioAttivitaSezione1Create: {
            get() {
                return CreateAvvioAttivitaModule.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create;
            }
        },
        PgaAvvioAttivitaSezione2Create: {
            get() {
                return CreateAvvioAttivitaModule.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create;
            }
        },
        PgaAvvioAttivitaSezione3Create: {
            get() {
                return CreateAvvioAttivitaModule.ModuloAvvioAttivita.PgaAvvioAttivitaSezione3Create;
            }
        },
        PgaAvvioAttivitaSezione4Create: {
            get() {
                return CreateAvvioAttivitaModule.ModuloAvvioAttivita.PgaAvvioAttivitaSezione4Create;
            }
        },
        PgaAvvioAttivitaSezione4HeaderCreate: {
            get() {
                return CreateAvvioAttivitaModule.ModuloAvvioAttivita.PgaAvvioAttivitaSezione4HeaderCreate;
            }
        },
        PgaAvvioAttivitaSezione5Create: {
            get() {
                return CreateAvvioAttivitaModule.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5Create;
            }
        },
        PgaAvvioAttivitaSezione5HeaderCreate: {
            get() {
                return CreateAvvioAttivitaModule.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5HeaderCreate;
            }
        }
    },
    methods: {
        addSezione4() {
            CreateAvvioAttivitaModule.ADD_TEAM4();
        },
        addSezione5() {
            CreateAvvioAttivitaModule.ADD_TEAM5();
        }
    }
})
export default class AvvioAttivitaPageCreate extends Vue { }
