


































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { PgaAvvioAttivitaSezione3CreateModel } from "../store";

@Component({
  data: () => ({
    enabled: false
  }),
  computed: {
    soluzioneReplicabile: {
      get() {
        return this.$props.Model.SoluzioneReplicabile;
      }
    },
    referenzaCliente: {
      get() {
        return this.$props.Model.ReferenzaCliente;
      }
    },
    altroReferenzaCliente: {
      get() {
        return this.$props.Model.AltroReferenzaCliente;
      }
    },
    documentiMarketing: {
      get() {
        return this.$props.Model.DocumentiMarketing;
      }
    },
    altroDocumentiMarketing: {
      get() {
        return this.$props.Model.AltroDocumentiMarketing;
      }
    }
  },
  methods: {
    checkDropDown(value) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (value.includes("ALTRO")) isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaAvvioAttivitaSezione3Details extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaAvvioAttivitaSezione3CreateModel;
}
