












































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateAvvioAttivitaModule } from "../store";
import { PgaAvvioAttivitaSezione1CreateModel } from "../store";
import { OfferServiceModule } from "../../store";
import PgaDateField from "../../../../components/inputs/PgaDateField.vue";

@Component({
  data: () => ({
    enabled: false
  }),
  components: {
    PgaDateField
  },
  computed: {
     PgaDateField: {
      get() {
        return this.$props.Model.PgaDateField;
      }
    },
    responsabileItems: {
      get() {
        return OfferServiceModule.getNOMERISORSA;
      }
    },
    responsabile: {
      get() {
        return this.$props.Model.Responsabile;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_RESPONSABILE(value);
      }
    },
    nomeCliente: {
      get() {
        return this.$props.Model.NomeCliente;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_NOMECLIENTE(value);
      }
    },
    clienteFinale: {
      get() {
        return this.$props.Model.ClienteFinale;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_CLIENTEFINALE(value);
      }
    },
    descrizioneCommessa: {
      get() {
        return this.$props.Model.DescrizioneCommessa;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_DESCRIZIONECOMMESSA(value);
      }
    },
    anticipoFornitura: {
      get() {
        return this.$props.Model.AnticipoFornitura;
      },
      set(value: boolean) {
        CreateAvvioAttivitaModule.SET_ANTICIPOFORNITURA(value);
      }
    },
    dataInizio: {
      get() {
        return this.$props.Model.DataInizio;
      },
      set(value: Date) {
        CreateAvvioAttivitaModule.SET_DATAINIZIO(value);
      }
    },
    dataFine: {
      get() {
        return this.$props.Model.DataFine;
      },
      set(value: Date) {
        CreateAvvioAttivitaModule.SET_DATAFINE(value);
      }
    },
    luogo: {
      get() {
        return this.$props.Model.Luogo;
      },
      set(value: boolean) {
        CreateAvvioAttivitaModule.SET_LUOGO(value);
      }
    },
    indirizzo: {
      get() {
        return this.$props.Model.Indirizzo;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_INDIRIZZO(value);
      }
    },
    acom: {
      get() {
        return this.$props.Model.Acom;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_ACOM(value);
      }
    },
    riferimenti: {
      get() {
        return this.$props.Model.Riferimenti;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_RIFERIMENTI(value);
      }
    },
    privacy: {
      get() {
        return this.$props.Model.Privacy;
      },
      set(value: boolean) {
        CreateAvvioAttivitaModule.SET_PRIVACY(value);
      }
    },
    note: {
      get() {
        return this.$props.Model.Note;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_NOTE(value);
      }
    }
  },
  methods: {
    checkDropDown(value) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (value.includes("Cliente")) isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaAvvioAttivitaSezione1Create extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaAvvioAttivitaSezione1CreateModel;
  async mounted() {
    const a = await OfferServiceModule.RetrievePokemons();
  }
}
