



















import Vue from "vue";
import { Component, Prop, Model, PropSync } from "vue-property-decorator";
import { CreateAvvioAttivitaModule } from "../store";
import { PgaAvvioAttivitaSezione4HeaderCreateModel } from "../store";
import { OfferServiceModule } from "../../store";

@Component({
  data: () => ({
  }),
  computed: {
    pm: {
      get() {
        return this.$props.Model.PM;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_PM4(value);
      }
    },
    mol: {
      get() {
        return this.$props.Model.Mol;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_MOL4(value);
      }
    },
    molItems: {
      get() {
        return OfferServiceModule.getMol;
      }
    },
  },
})
export default class PgaAvvioAttivitaSezione4HeaderCreate extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaAvvioAttivitaSezione4HeaderCreateModel;
}
