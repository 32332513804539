











































// @ is an alias to /src
import { Vue, Component } from "vue-property-decorator";
import PgaAvvioAttivitaSezione1Details from "./Components/PgaAvvioAttivitaSezione1Details.vue";
import PgaAvvioAttivitaSezione2Details from "./Components/PgaAvvioAttivitaSezione2Details.vue";
import PgaAvvioAttivitaSezione3Details from "./Components/PgaAvvioAttivitaSezione3Details.vue";
import PgaAvvioAttivitaSezione4Details from "./Components/PgaAvvioAttivitaSezione4Details.vue";
import PgaAvvioAttivitaSezione4HeaderDetails from "./Components/PgaAvvioAttivitaSezione4HeaderDetails.vue";
import PgaAvvioAttivitaSezione5Details from "./Components/PgaAvvioAttivitaSezione5Details.vue";
import PgaAvvioAttivitaSezione5HeaderDetails from "./Components/PgaAvvioAttivitaSezione5HeaderDetails.vue";
import { CreateAvvioAttivitaModule } from "./store";

@Component({
  components: {
    PgaAvvioAttivitaSezione1Details,
    PgaAvvioAttivitaSezione2Details,
    PgaAvvioAttivitaSezione3Details,
    PgaAvvioAttivitaSezione4Details,
    PgaAvvioAttivitaSezione4HeaderDetails,
    PgaAvvioAttivitaSezione5Details,
    PgaAvvioAttivitaSezione5HeaderDetails
  },
  data: () => ({}),
  computed: {
    PgaAvvioAttivitaSezione1Details: {
      get() {
        return CreateAvvioAttivitaModule.ModuloAvvioAttivita
          .PgaAvvioAttivitaSezione1Create;
      }
    },
    PgaAvvioAttivitaSezione2Details: {
      get() {
        return CreateAvvioAttivitaModule.ModuloAvvioAttivita
          .PgaAvvioAttivitaSezione2Create;
      }
    },
    PgaAvvioAttivitaSezione3Details: {
      get() {
        return CreateAvvioAttivitaModule.ModuloAvvioAttivita
          .PgaAvvioAttivitaSezione3Create;
      }
    },
    PgaAvvioAttivitaSezione4Details: {
      get() {
        return CreateAvvioAttivitaModule.ModuloAvvioAttivita
          .PgaAvvioAttivitaSezione4Create;
      }
    },
    PgaAvvioAttivitaSezione4HeaderDetails: {
      get() {
        return CreateAvvioAttivitaModule.ModuloAvvioAttivita
          .PgaAvvioAttivitaSezione4HeaderCreate;
      }
    },
    PgaAvvioAttivitaSezione5Details: {
      get() {
        return CreateAvvioAttivitaModule.ModuloAvvioAttivita
          .PgaAvvioAttivitaSezione5Create;
      }
    },
    PgaAvvioAttivitaSezione5HeaderDetails: {
      get() {
        return CreateAvvioAttivitaModule.ModuloAvvioAttivita
          .PgaAvvioAttivitaSezione5HeaderCreate;
      }
    }
  }
})
export default class AvvioAttivitaPageDetails extends Vue {}
