





































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { CreateAvvioAttivitaModule } from "../store";
import { PgaAvvioAttivitaSezione2CreateModel } from "../store";
import { OfferServiceModule } from "../../store";

@Component({
  data: () => ({
    enabledVincoli: false
  }),
  computed: {
    contratto: {
      get() {
        return this.$props.Model.Contratto;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_CONTRATTO(value);
      }
    },
    ordine: {
      get() {
        return this.$props.Model.Ordine;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_ORDINE(value);
      }
    },
    tipologiaOrdine: {
      get() {
        return this.$props.Model.TipologiaOrdine;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_TIPOLOGIAORDINE(value);
      }
    },
    tipologiaOrdineItems: {
      get() {
        return OfferServiceModule.getTIPOLOGIAOFFERTA;
      }
    },
    pianoFatturazione: {
      get() {
        return this.$props.Model.PianoFatturazione;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_PIANOFATTURAZIONE(value);
      }
    },
    note2: {
      get() {
        return this.$props.Model.Note2;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_NOTE2(value);
      }
    },
    altroVincolo: {
      get() {
        return this.$props.Model.AltroVincolo;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_ALTROVINCOLO(value);
      }
    },
    vincoliItems: {
      get() {
        return OfferServiceModule.getVINCOLI;
      }
    },
    vincoli: {
      get() {
        return this.$props.Model.Vincoli;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_VINCOLI(value);
      }
    },
    subVincoliItems: {
      get() {
        return OfferServiceModule.getSUBVINCOLI;
      }
    },
    subVincoli: {
      get() {
        return this.$props.Model.SubVincoli;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_SUBVINCOLI(value);
      }
    },
    mol: {
      get() {
        return this.$props.Model.Mol;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_MOL2(value);
      }
    },
    molItems: {
      get() {
        return OfferServiceModule.getMol;
      }
    },
    nomeRepository: {
      get() {
        return this.$props.Model.NomeRepository;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_NOMEREPOSITORY(value);
      }
    }
  },
  methods: {
    checkVincoli(value: string[]) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (
          value.includes(
            "Regolarità retributiva, contributiva, previdenziale, assicurativa e fiscale"
          )
        )
          isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaAvvioAttivitaSezione2Create extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaAvvioAttivitaSezione2CreateModel;
}
