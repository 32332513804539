
























































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { PgaAvvioAttivitaSezione2CreateModel } from "../store";

@Component({
  data: () => ({
    enabled: false
  }),
  computed: {
    contratto: {
      get() {
        return this.$props.Model.Contratto;
      }
    },
    ordine: {
      get() {
        return this.$props.Model.Ordine;
      }
    },
    nomeRepository: {
      get() {
        return this.$props.Model.NomeRepository;
      }
    },
    pianoFatturazione: {
      get() {
        return this.$props.Model.PianoFatturazione;
      }
    },
    tipologiaOrdine: {
      get() {
        return this.$props.Model.TipologiaOrdine;
      }
    },
    mol: {
      get() {
        return this.$props.Model.Mol;
      }
    },
    vincoli: {
      get() {
        return this.$props.Model.Vincoli;
      }
    },
    subVincoli: {
      get() {
        return this.$props.Model.SubVincoli;
      }
    },
    altroVincolo: {
      get() {
        return this.$props.Model.AltroVincolo;
      }
    },
    note2: {
      get() {
        return this.$props.Model.Note2;
      }
    }
  },
  methods: {
    checkDropDown(value) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (
          value.includes(
            "Regolarità retributiva, contributiva, previdenziale, assicurativa e fiscale"
          )
        )
          isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaAvvioAttivitaSezione2Details extends Vue {
  @Model("", { type: Object })
  readonly Model!: PgaAvvioAttivitaSezione2CreateModel;
}
