










































import Vue from "vue";
import { Component, Prop, Model, PropSync } from "vue-property-decorator";
import { CreateAvvioAttivitaModule } from "../store";
import { PgaAvvioAttivitaSezione3CreateModel } from "../store";
import { OfferServiceModule } from "../../store";

@Component({
  data: () => ({
    enabled: false
  }),
  computed: {
    soluzioneReplicabile: {
      get() {
        return this.$props.Model.SoluzioneReplicabile;
      },
      set(value: boolean) {
        CreateAvvioAttivitaModule.SET_SOLUZIONEREPLICABILE(value);
      }
    },
    referenzaClienteItems: {
      get() {
        return OfferServiceModule.getREFERENZACLIENTE;
      }
    },
    referenzaCliente: {
      get() {
        return this.$props.Model.ReferenzaCliente;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_REFERENZACLIENTE(value);
      }
    },
    altroReferenzaCliente: {
      get() {
        return this.$props.Model.AltroReferenzaCliente;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_ALTROREFERENZACLIENTE(value);
      }
    },
    documentiMarketingItems: {
      get() {
        return OfferServiceModule.getDOCUMENTIMARKETING;
      }
    },
    documentiMarketing: {
      get() {
        return this.$props.Model.DocumentiMarketing;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_DOCUMENTIMARKETING(value);
      }
    },
    altroDocumentiMarketing: {
      get() {
        return this.$props.Model.AltroDocumentiMarketing;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_ALTRODOCUMENTIMARKETING(value);
      }
    }
  },
  methods: {
    checkDropDown(value) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (value.includes("ALTRO")) isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaAvvioAttivitaSezione3Create extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaAvvioAttivitaSezione3CreateModel;
}
