

















import Vue from "vue";
import { Component } from "vue-property-decorator";
import { CreateAvvioAttivitaModule } from "../store";

@Component({
  data() {
    return {
      search: "",
      headers: [
        {
          text: "Nominativo",
          align: "start",
          sortable: false,
          value: "Nominativo"
        },
        { text: "Ruolo", value: "Ruolo" },
        { text: "Giornate", value: "Giornate" }
      ]
    };
  },
  computed: {
    Sezione5Items: {
      get() {
        return CreateAvvioAttivitaModule.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5Create;
      }
    }
  }
})
export default class Sezione5DisplayClass extends Vue {}
