
















import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { PgaAvvioAttivitaSezione5HeaderCreateModel } from "../store";

@Component({
  data: () => ({
    enabled: false
  }),
  computed: {
    data: {
      get() {
        return this.$props.Model.Data;
      }
    },
    pm: {
      get() {
        return this.$props.Model.PM;
      }
    },
    mol: {
      get() {
        return this.$props.Model.Mol;
      }
    }
  }
})
export default class PgaAvvioAttivitaSezione5HeaderCreate extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaAvvioAttivitaSezione5HeaderCreateModel;
}
