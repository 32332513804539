






















import Vue from "vue";
import { Component, Prop, Model, PropSync } from "vue-property-decorator";
import { CreateAvvioAttivitaModule } from "../store";
import { PgaAvvioAttivitaSezione5HeaderCreateModel } from "../store";
import { OfferServiceModule } from "../../store";

@Component({
  data: () => ({}),
  computed: {
    pm: {
      get() {
        return this.$props.Model.PM;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_PM5(value);
      }
    },
    data: {
      get() {
        return this.$props.Model.Data;
      },
      set(value: Date) {
        CreateAvvioAttivitaModule.SET_DATE5(value);
      }
    },
    mol: {
      get() {
        return this.$props.Model.Mol;
      },
      set(value: string) {
        CreateAvvioAttivitaModule.SET_MOL5(value);
      }
    },
    molItems: {
      get() {
        return OfferServiceModule.getMol;
      }
    }
  }
})
export default class PgaAvvioAttivitaSezione5HeaderCreate extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaAvvioAttivitaSezione5HeaderCreateModel;
}
