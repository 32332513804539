



























import Vue from "vue";
import { Component, Prop, Model, Emit } from "vue-property-decorator";
import { CreateAvvioAttivitaModule } from "./store";
import PgaBottomNavigation from "@/feature/Shared/Layout/PgaBottomNavigation.vue";
import PgaAuthorizedContent from "@/components/PgaAuthorizedContent.vue";
import SharedMixin from "@/mixins/SharedMixin";
@Component({
  components: {
    PgaBottomNavigation,
    PgaAuthorizedContent
  },
  mixins: [SharedMixin],
  data() {
    return {
      buttons: {
        create: {
          Icon: "mdi-plus",
          Text: "Create",
          Url: "/avvioattivita/create"
        }
      },
      bottomNav: "recent",
      search: "",
      headers: [
        {
          text: "Responsabile",
          value: "Responsabile"
        },
        { text: "Descrizione Commessa", value: "DescrizioneCommessa" },
        { text: "Nome Cliente", value: "NomeCliente" },
        { text: "Cliente Finale", value: "ClienteFinale" }
      ]
    };
  },
  computed: {},
})
export default class AvvioAttivitaIndex extends Vue {
  details(data: any) {
    this.$router.push({ path: "avvioattivita/details" });
  }
  get items() {
    return [
      CreateAvvioAttivitaModule.ModuloAvvioAttivita
        .PgaAvvioAttivitaSezione1Create
    ];
  }
}
