



































































































































import Vue from "vue";
import { Component, Model } from "vue-property-decorator";
import { PgaAvvioAttivitaSezione1CreateModel } from "../store";

@Component({
  data: () => ({
    enabled: false
  }),
  computed: {
    responsabile: {
      get() {
        return this.$props.Model.Responsabile;
      }
    },
    nomeCliente: {
      get() {
        return this.$props.Model.NomeCliente;
      }
    },
    clienteFinale: {
      get() {
        return this.$props.Model.ClienteFinale;
      }
    },
    descrizioneCommessa: {
      get() {
        return this.$props.Model.DescrizioneCommessa;
      }
    },
    anticipoFornitura: {
      get() {
        return this.$props.Model.AnticipoFornitura;
      }
    },
    dataInizio: {
      get() {
        return this.$props.Model.DataInizio;
      }
    },
    dataFine: {
      get() {
        return this.$props.Model.DataFine;
      }
    },
    luogo: {
      get() {
        return this.$props.Model.Luogo;
      }
    },
    indirizzo: {
      get() {
        return this.$props.Model.Indirizzo;
      }
    },
    acom: {
      get() {
        return this.$props.Model.Acom;
      }
    },
    riferimenti: {
      get() {
        return this.$props.Model.Riferimenti;
      }
    },
    privacy: {
      get() {
        return this.$props.Model.Privacy;
      }
    },
    note: {
      get() {
        return this.$props.Model.Note;
      }
    }
  },
  methods: {
    checkDropDown(value) {
      let isVisible: boolean;
      isVisible = false;
      if (value) {
        if (value.includes("Cliente")) isVisible = true;
      }
      return isVisible;
    }
  }
})
export default class PgaAvvioAttivitaSezioe1Details extends Vue {
  @Model("", { type: Object }) readonly Model!: PgaAvvioAttivitaSezione1CreateModel;
}
