import { VuexModule, Module, Mutation, getModule } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import '@/App.container';
import MutationPayload from '@/types/Payload';

export interface CreateAvvioAttivitaState {
    ModuloAvvioAttivita: ModuloAvvioAttivita;
}

export interface ModuloAvvioAttivita {
    PgaAvvioAttivitaSezione1Create: PgaAvvioAttivitaSezione1CreateModel;
    PgaAvvioAttivitaSezione2Create: PgaAvvioAttivitaSezione2CreateModel;
    PgaAvvioAttivitaSezione3Create: PgaAvvioAttivitaSezione3CreateModel;
    PgaAvvioAttivitaSezione4Create: Array<PgaAvvioAttivitaSezione4CreateModel>;
    PgaAvvioAttivitaSezione4HeaderCreate: PgaAvvioAttivitaSezione4HeaderCreateModel;
    PgaAvvioAttivitaSezione5Create: Array<PgaAvvioAttivitaSezione5CreateModel>;
    PgaAvvioAttivitaSezione5HeaderCreate: PgaAvvioAttivitaSezione5HeaderCreateModel;
}

export interface PgaAvvioAttivitaSezione1CreateModel {
    Responsabile: string;
    NomeCliente: string;
    ClienteFinale: string;
    DescrizioneCommessa: string;
    AnticipoFornitura: boolean;
    DataInizio: Date;
    DataFine: Date;
    Luogo: boolean;
    Indirizzo: string;
    Acom: string;
    Riferimenti: string;
    Privacy: boolean;
    Note: string;

}

export interface PgaAvvioAttivitaSezione2CreateModel {
    Contratto: string;
    Ordine: string;
    TipologiaOrdine: string;
    PianoFatturazione: string;
    Note2: string;
    Vincoli: string;
    Note20: string;
    Mol: string;
    NomeRepository: string;
    SubVincoli: string;
    AltroVincolo: string;
}

export interface PgaAvvioAttivitaSezione3CreateModel {
    SoluzioneReplicabile: boolean;
    ReferenzaCliente: string;
    AltroReferenzaCliente: string;
    DocumentiMarketing: string;
    AltroDocumentiMarketing: string;
}

export interface PgaAvvioAttivitaSezione4CreateModel {
    Nominativo: string;
    Ruolo: string;
    Giornate: number;
}

export interface PgaAvvioAttivitaSezione4HeaderCreateModel {
    PM: string;
    Mol: string;
}

export interface PgaAvvioAttivitaSezione5CreateModel {
    Nominativo: string;
    Ruolo: string;
    Giornate: number;
}

export interface PgaAvvioAttivitaSezione5HeaderCreateModel {
    Data: Date;
    PM: string;
    Mol: string;
}

@Module({ namespaced: true, dynamic: true, store, name: 'CreateAvvioAttivitaModule', })
class CreateAvvioAttivitaStore extends VuexModule implements CreateAvvioAttivitaState {
    ModuloAvvioAttivita: ModuloAvvioAttivita = {
        PgaAvvioAttivitaSezione1Create: {
            Responsabile: "",
            NomeCliente: "",
            ClienteFinale: "",
            DescrizioneCommessa: "",
            AnticipoFornitura: false,
            DataInizio: new Date(),
            DataFine: new Date(),
            Luogo: false,
            Indirizzo: "",
            Acom: "",
            Riferimenti: "",
            Privacy: false,
            Note: "",
        },
        PgaAvvioAttivitaSezione2Create: {
            Contratto: "",
            Ordine: "",
            TipologiaOrdine: "",
            PianoFatturazione: "",
            Note2: "",
            Vincoli: "",
            Note20: "",
            Mol: "",
            NomeRepository: "",
            SubVincoli: "",
            AltroVincolo: "",

        },
        PgaAvvioAttivitaSezione3Create: {
            SoluzioneReplicabile: false,
            ReferenzaCliente: "",
            AltroReferenzaCliente: "",
            DocumentiMarketing: "",
            AltroDocumentiMarketing: "",
        },
        PgaAvvioAttivitaSezione4Create: [],
        PgaAvvioAttivitaSezione4HeaderCreate: {
            PM: "",
            Mol: "",
        },
        PgaAvvioAttivitaSezione5Create: [],
        PgaAvvioAttivitaSezione5HeaderCreate: {
            Data: new Date(),
            PM: "",
            Mol: "",
        },
    };

    //Sezione 1 
    @Mutation
    public SET_RESPONSABILE(Responsabile: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.Responsabile = Responsabile;
    }
    @Mutation
    public SET_NOMECLIENTE(NomeCliente: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.NomeCliente = NomeCliente;
    }
    @Mutation
    public SET_CLIENTEFINALE(ClienteFinale: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.ClienteFinale = ClienteFinale;
    }
    @Mutation
    public SET_DESCRIZIONECOMMESSA(DescrizioneCommessa: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.DescrizioneCommessa = DescrizioneCommessa;
    }
    @Mutation
    public SET_ANTICIPOFORNITURA(AnticipoFornitura: boolean): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.AnticipoFornitura = AnticipoFornitura;
    }
    @Mutation
    public SET_DATAINIZIO(DataInizio: Date): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.DataInizio = DataInizio;
    }
    @Mutation
    public SET_DATAFINE(DataFine: Date): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.DataFine = DataFine;
    }
    @Mutation
    public SET_LUOGO(Luogo: boolean): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.Luogo = Luogo;
    }
    @Mutation
    public SET_INDIRIZZO(Indirizzo: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.Indirizzo = Indirizzo;
    }
    @Mutation
    public SET_ACOM(Acom: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.Acom = Acom;
    }
    @Mutation
    public SET_RIFERIMENTI(Riferimenti: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.Riferimenti = Riferimenti;
    }
    @Mutation
    public SET_PRIVACY(Privacy: boolean): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.Privacy = Privacy;
    }
    @Mutation
    public SET_NOTE(Note: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione1Create.Note = Note;
    }

    //Sezione2
    @Mutation
    public SET_CONTRATTO(Contratto: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.Contratto = Contratto;
    }
    @Mutation
    public SET_ORDINE(Ordine: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.Ordine = Ordine;
    }
    @Mutation
    public SET_TIPOLOGIAORDINE(TipologiaOrdine: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.TipologiaOrdine = TipologiaOrdine;
    }
    @Mutation
    public SET_PIANOFATTURAZIONE(PianoFatturazione: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.PianoFatturazione = PianoFatturazione;
    }
    @Mutation
    public SET_NOTE2(Note2: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.Note2 = Note2;
    }
    @Mutation
    public SET_VINCOLI(Vincoli: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.Vincoli = Vincoli;
    }
    @Mutation
    public SET_MOL2(Mol: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.Mol = Mol;
    }
    @Mutation
    public SET_NOMEREPOSITORY(NomeRepository: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.NomeRepository = NomeRepository;
    }
    @Mutation
    public SET_SUBVINCOLI(SubVincoli: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.SubVincoli = SubVincoli;
    }
    @Mutation
    public SET_ALTROVINCOLO(AltroVincolo: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione2Create.AltroVincolo = AltroVincolo;
    }

    //PgaAvvioAttivitaSezione 3
    @Mutation
    public SET_SOLUZIONEREPLICABILE(SoluzioneReplicabile: boolean): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione3Create.SoluzioneReplicabile = SoluzioneReplicabile;
    }
    @Mutation
    public SET_REFERENZACLIENTE(ReferenzaCliente: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione3Create.ReferenzaCliente = ReferenzaCliente;
    }
    @Mutation
    public SET_ALTROREFERENZACLIENTE(AltroReferenzaCliente: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione3Create.AltroReferenzaCliente = AltroReferenzaCliente;
    }
    @Mutation
    public SET_DOCUMENTIMARKETING(DocumentiMarketing: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione3Create.DocumentiMarketing = DocumentiMarketing;
    }
    @Mutation
    public SET_ALTRODOCUMENTIMARKETING(AltroDocumentiMarketing: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione3Create.AltroDocumentiMarketing = AltroDocumentiMarketing;
    }

    //PgaAvvioAttivitaSezione 4
    @Mutation
    public SET_NOMINATIVO4(payload: MutationPayload<string>): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione4Create[payload.index].Nominativo = payload.value;
    }
    @Mutation
    public SET_RUOLO4(payload: MutationPayload<string>): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione4Create[payload.index].Ruolo = payload.value;
    }
    @Mutation
    public SET_GIORNATE4(payload: MutationPayload<number>): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione4Create[payload.index].Giornate = payload.value;
    }
    @Mutation
    public REMOVE_TEAM4(index: number): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione4Create.splice(index, 1);
    }
    @Mutation
    public ADD_TEAM4(): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione4Create.push({
            Nominativo: "",
            Ruolo: "",
            Giornate: 0,
        } as PgaAvvioAttivitaSezione4CreateModel);
    }

    //PgaAvvioAttivitaSezione 4 header
    @Mutation
    public SET_PM4(PM: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione4HeaderCreate.PM = PM;
    }
    @Mutation
    public SET_MOL4(Mol: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione4HeaderCreate.Mol = Mol;
    }

    //PgaAvvioAttivitaSezione 5
    @Mutation
    public SET_NOMINATIVO5(payload: MutationPayload<string>): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5Create[payload.index].Nominativo = payload.value;
    }
    @Mutation
    public SET_RUOLO5(payload: MutationPayload<string>): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5Create[payload.index].Ruolo = payload.value;
    }
    @Mutation
    public SET_GIORNATE5(payload: MutationPayload<number>): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5Create[payload.index].Giornate = payload.value;
    }
    @Mutation
    public REMOVE_TEAM5(index: number): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5Create.splice(index, 1);
    }
    @Mutation
    public ADD_TEAM5(): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5Create.push({
            Nominativo: "",
            Ruolo: "",
            Giornate: 0,
        } as PgaAvvioAttivitaSezione5CreateModel);
    }

    //PgaAvvioAttivitaSezione 5 header
    @Mutation
    public SET_DATE5(Data: Date): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5HeaderCreate.Data = Data;
    }
    @Mutation
    public SET_PM5(PM: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5HeaderCreate.PM = PM;
    }
    @Mutation
    public SET_MOL5(Mol: string): void {
        this.ModuloAvvioAttivita.PgaAvvioAttivitaSezione5HeaderCreate.Mol = Mol;
    }
}

export const CreateAvvioAttivitaModule = getModule(CreateAvvioAttivitaStore);
